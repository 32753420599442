var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"md":24,"lg":8}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"原始密码","required":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'oldPassword',
        {rules: [{ required: true, message: '请输入原始密码' }]}
      ]),expression:"[\n        'oldPassword',\n        {rules: [{ required: true, message: '请输入原始密码' }]}\n      ]"}],attrs:{"type":"Password","placeholder":"请输入原始密码"}})],1),_c('a-form-item',{attrs:{"label":"新密码","required":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'newPassword',
        {rules: [{ required: true, message: '请输入新密码' },
        { validator: _vm.validatorPassword, message: '必须包含数字、大小写字母、特殊符号且大于等于8位'}]}
      ]),expression:"[\n        'newPassword',\n        {rules: [{ required: true, message: '请输入新密码' },\n        { validator: validatorPassword, message: '必须包含数字、大小写字母、特殊符号且大于等于8位'}]}\n      ]"}],attrs:{"type":"Password","placeholder":"请输入新密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码","required":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'confirmPassword',
        {rules: [{ required: true, message: '请输入确认密码' }]}
      ]),expression:"[\n        'confirmPassword',\n        {rules: [{ required: true, message: '请输入确认密码' }]}\n      ]"}],attrs:{"type":"Password","placeholder":"请输入确认密码"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("修改密码")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }